@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: url('../fonts/Inter-Thin.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  src: url('../fonts/Inter-ExtraLight.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url('../fonts/Inter-Light.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('../fonts/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('../fonts/Inter-Medium.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url('../fonts/Inter-SemiBold.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url('../fonts/Inter-Bold.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  src: url('../fonts/Inter-ExtraBold.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  src: url('../fonts/Inter-Black.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 100;
  font-style: italic;
  src: url('../fonts/Inter-ThinItalic.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  font-style: italic;
  src: url('../fonts/Inter-ExtraLightItalic.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/Inter-LightItalic.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/Inter-Italic.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: italic;
  src: url('../fonts/Inter-MediumItalic.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-style: italic;
  src: url('../fonts/Inter-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/Inter-BoldItalic.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  font-style: italic;
  src: url('../fonts/Inter-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  font-style: italic;
  src: url('../fonts/Inter-BlackItalic.ttf');
}
